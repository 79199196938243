<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 13L20 18C20 19.1046 19.1046 20 18 20L6 20C4.89543 20 4 19.1046 4 18L4 13"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2071 13.7071C15.5976 13.3166 15.5976 12.6834 15.2071 12.2929C14.8166 11.9024 14.1834 11.9024 13.7929 12.2929L13 13.0858L13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4L11 13.0858L10.2071 12.2929C9.81658 11.9024 9.18342 11.9024 8.79289 12.2929C8.40237 12.6834 8.40237 13.3166 8.79289 13.7071L11.2929 16.2071C11.6834 16.5976 12.3166 16.5976 12.7071 16.2071L15.2071 13.7071Z"
      :fill="accent"
    />
  </svg>
</template>
